<template>
  <div class="mx-3">
    <FilterDashboard />

    <div>
      <DasboardNav />
    </div>

    <div>
      <template v-if="isTableLoading">
        <v-progress-linear indeterminate color="primary"></v-progress-linear>
      </template>

      <table class="">
        <thead class="px-4 py-4 ">
          <tr class="px-4 py-4 text-center tr-class" style="height: 50px">
            <th class="font-class align-text" style="width: 18%;">
              {{ $t("message.order-number") }}
            </th>
            <th  class="font-class align-text" style="width: 17%;">
              {{ $t("message.account-id") }}
            </th>
            <th  style="width: 10%;"></th>
            <th class="font-class align-text" style="width: 8%;">
              {{ $t("message.order-count") }}
            </th>
            <th class="font-class align-text" style="width: 10%;">
              {{ $t("message.order-total-fee") }}
            </th>
            <th class="font-class align-text" style="width: 13%;">
              {{ $t("message.order-date") }}
            </th>
            <th class="font-class align-text" style="width: 15%;">
              {{ $t("message.delivery-date") }}
            </th>
            <th  style="width: 10%;"></th>
          </tr>
        </thead>
        <template v-if="orderProcessing.length == 0">
          <tbody>
            <tr class="text-center">
              <td colspan="7" style="text-align: center;">
                {{ $t("message.no-data") }}
              </td>
            </tr>
          </tbody>
        </template>
        <template v-else>
          <tbody v-for="(order, id) in orderProcessing" :key="id">
            <tr
              :class="{ opened: opened.includes(order.id) }"
              style="border-bottom: 1px solid #BDBDBD"
            >
              <!-- <template > -->
                <td style="text-align: center;"><a href="" @click.prevent="toOrderDetail(order.id)">{{ order.order_no }}</a></td>
                <td  style="text-align: center;"><a href="" @click.prevent="toCustomerDetail(order)">{{ order.customer_name }}</a></td>
                <td  style="text-align: center;"></td>
              <!-- </template> -->
              <td style="text-align: center;">{{ order.order_count }}</td>
              <td style="text-align: center;">{{ moneyFormat(parseInt(order.total_order_price)) }}</td>
              <td style="text-align: center;">{{ order.created_at }}</td>
              <td style="text-align: center;">{{ order.delivery_at }}</td>
              <td style="text-align: center;" @click="toggle(order.id)">
                <v-btn icon style="text-transform: none;">
                  <v-icon v-if="!opened.includes(order.id)" left>{{
                    "mdi-chevron-down"
                  }}</v-icon>
                  <v-icon v-else left>{{ "mdi-chevron-up" }}</v-icon>
                  {{ $t("message.detail") }}
                </v-btn>
              </td>
            </tr>

            <template v-if="opened.includes(order.id)" class="">
              <tr
                id="example1"
                class="text-center expand-table-class hover-class"
                style="background-color: #E8EAF6;margin-top: 3em; margin-bottom: 3em;border-bottom: 1px solid #BDBDBD"
              >
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.product-id") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.product-name") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.product-code") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.out-stock") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.qty") }}

                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.price-display-per-item") }}

                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.price-display") }}
                </th>
                <th
                  class="font-class"
                  style="padding-top: 1em;padding-bottom: 1em;"
                >
                  {{ $t("message.tax-type-display") }}
                </th>
              </tr>
              <tr
                id="example2"
                class="hover-class"
                v-for="(detail, idx) in order.details"
                :key="idx"
                style="background-color: #E8EAF6;border-bottom: 1px solid #BDBDBD"
              >
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{ detail.item_id }}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{ detail.item_name }}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{ detail.item_code }}
                </td>
                <td
                  class="font-class d-flex justify-center"
                  style="padding-bottom: 1em;text-align: center;float: center;"
                >
                  <v-checkbox
                    v-model="selected"
                    :value="detail.item_id"
                  ></v-checkbox>
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{ detail.qty }}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{detail.price_display_peritem}}
                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{detail.price_display}}

                </td>
                <td
                  class="font-class"
                  style="padding-bottom: 1em;text-align: center;"
                >
                  {{detail.tax_type_display}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{toFixed(order.subtotal)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_exclude_taxable_value_8 > 0"
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-exclude-taxable-value-8") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_exclude_taxable_value_8)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_tax_8 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-tax-8") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_consumption_tax_8)}}

                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_exclude_taxable_value_10 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-exclude-taxable-value-10") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_exclude_taxable_value_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_tax_10 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-tax-10") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_consumption_tax_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_include_taxable_value_8 > 0"
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-include-taxable-value-8") }}
                </td>
                <td colspan="1"  class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_include_taxable_value_8)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_taxable_8 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-taxable-8") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_consumption_taxable_8)}}

                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_include_taxable_value_10 > 0"

              >
                <td colspan="6"></td>
                <td  class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-include-taxable-value-10") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_include_taxable_value_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_consumption_taxable_10 > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-consumption-taxable-10") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_consumption_taxable_10)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
                v-if="order.subtotal_non_taxable_value > 0"

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.subtotal-non-taxable-value") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.subtotal_non_taxable_value)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "

              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.delivery-fee-display") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.delivery_fee_display)}}
                </td>
              </tr>
              <tr
                id="example5"
                class="hover-class"
                style="background-color: #E8EAF6; "
              >
                <td colspan="6"></td>
                <td class="pe-8" style="text-align:end;">
                  {{ $t("message.total-amount-delivery-fee-store") }}
                </td>
                <td colspan="1" class="ps-8" style="text-align:start;">
                  {{decimal(order.total_amount_w_delivery_fee_per_store)}}
                </td>
              </tr>

              <!-- button -->
              <tr
                id="example4"
                class="hover-class"
                style="background-color: #E8EAF6"
              >
                <td colspan="6" class="pl-5">
                  <span>【備考】</span>
                  <p v-if="order.order_shipping_type == 1">置き配を希望する</p>
                  <pre>{{ order.notes }}</pre>
                </td>
                <td colspan="2"
                  style="text-align: center; padding-top: 1em; padding-bottom: 2em"
                >
                  <v-btn
                    :disabled="order.is_payment === 1"
                    color="#7986cb"
                    class="white--text"
                    style="text-transform: none; margin-right: 20px"
                    @click.prevent="payWithCC(order.id)"
                    :loading="isLoading"
                  >
                    <span>{{ $t("message.payment-cc") }}</span>
                  </v-btn>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </table>
    </div>
  </div>
</template>

<script>
import { mdiMagnify } from "@mdi/js";
import FilterDashboard from "../../components/FilterDashboard.vue";
import DasboardNav from "../../components/DasboardNav.vue";
import moneyFormat from "../../helpers/moneyFormat";
export default {
  name: "OrderList",
  components: {
    FilterDashboard,
    DasboardNav,
  },
  data: () => ({
    iconSearch: mdiMagnify,
    option: {},
    opened: [],
    isExpanded: false,
    singleSelect: false,
    selected: [],
    checkbox2: false,
    isLoading: false,
    isTableLoading: false,
  }),
  watch: {
    orderProcessing(newVal, oldVal){
    },
    orderProcessing: {
      handler: function(newVal){
      },
      deep: true
    }
  },
  computed: {

    orderProcessing() {
      return this.$store.state.dashboard_module.dashboardProcessing;
    },
  },
  created() {
    this.dispatchOrderProcessing();
  },
  methods: {
    toOrderDetail(order_id) {
      this.$router.push(`/order/${order_id}`)
    },
    toCustomerDetail(order) {
      this.$router.push(`/customer/detail/${order.customer_id}`)
    },
    toFixed(money){
      let strMoney = money.toString()
      let flag = false
      for (let i = 0; i < strMoney.length; i++) {
        if (strMoney[i] == '.') {
          flag = true
          break
        } else {
          flag = false
        }
      }
      if (!flag) {
        return money
      } else {
        return money.toFixed(2)
      }
    },
    decimal(money) {
      return money.toFixed(0)
    },
    moneyFormat(money){
      return moneyFormat(money)
    },
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.isExpanded = true;
        this.opened.splice(index, 1);
      } else {
        this.isExpanded = false;
        this.opened.push(id);
      }
    },
    async dispatchOrderProcessing() {
      // this.isTableLoading = true;
      await this.$store.dispatch("dashboard_module/shopAll");
      await this.$store.dispatch("dashboard_module/deliveryDate");
      await this.$store.dispatch("dashboard_module/dashboardOrderProcessing");
      // this.isTableLoading = false;
    },
    async payWithCC(orderId) {
      this.isLoading = true;
      await this.$store.dispatch("dashboard_module/paymentOrder", {
        order_id: orderId,
        item_shortage_id: this.selected,
      });
      this.dispatchOrderProcessing()
      this.isLoading = false;

    },
  },
};
</script>

<style>
#example1:hover {
  background-color: #e8eaf6 !important;
}
#example2:hover {
  background: #e8eaf6 !important;
}
#example3:hover {
  background: #e8eaf6 !important;
}
#example4:hover {
  background: #e8eaf6 !important;
}
#example5:hover {
  background: #e8eaf6 !important;
}
#example6:hover {
  background: #e8eaf6 !important;
}
tr-class {
  background: #7986cb !important;
}
font-class {
  font-size: 14px;
}
thead {
  background: #7986cb !important;
  text-transform: capitalize;
}
tr td {
  /* text-align: center !important; */
}
thead tr th {
  color: white !important;
  font-size: 15px !important;
}
.align-text {
  text-align: center !important;
  vertical-align: middle !important;
}
table {
}
table {
  width: 100%;
}
/* td { border: solid 1px #000; } */
tr:last-child td:first-child {
  border-bottom-left-radius: 20px;
  /* border: 1px solid rgb(132, 132, 132) */
}

tr:last-child td:last-child {
  border-bottom-right-radius: 20px;
}
tbody tr:hover:not(.v-table__expanded__content) {
  background: transparent !important;
}
</style>
